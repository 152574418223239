<template>
  <div class="index-lesson-modules">
    <div class="position-relative overflow-hidden">
      <div class="container">
        <swiper
          :slidesPerView="1.5"
          :breakpoints="breakpoints"
          :centeredSlides="true"
          :initialSlide="initialSlide"
        >
          <swiper-slide v-for="mod in modules" :key="mod.id">
            <button
              :style="`background-image: url(${mod.assets.big})`"
              @click="activate(mod)"
            >
              <div
                v-if="active && active.id === mod.id"
                class="active-border"
              ></div>
              <div v-if="mod.is_blocked" class="block">
                <i class="fa-regular fa-lock"></i>
                <span>
                  Este conteúdo estará disponível em breve.
                  {{ publishedDate(mod) }}
                </span>
              </div>
            </button>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="container">
      <lessons-list v-if="active" :key="active.id" :module="active" />
    </div>
  </div>
</template>

<script>
import LessonsList from "./LessonsList";
import Swiper from "../Swiper.vue";
import SwiperSlide from "../SwiperSlide.vue";
import { mapGetters } from "vuex";
import { sortBy, get } from "lodash";

export default {
  components: { LessonsList, Swiper, SwiperSlide },
  props: {
    course: Object,
  },
  data() {
    const modules = sortBy(this.course.modules, ["sort"]);
    let active = get(modules, "[0]");
    let initialSlide = 0;

    if (this.$route.query.module) {
      for (let i in modules) {
        if (modules[i].id === parseInt(this.$route.query.module)) {
          active = modules[i];
          initialSlide = parseInt(i);
        }
      }
    }

    return {
      modules,
      active,
      initialSlide,
      breakpoints: {
        450: { slidesPerView: 1.8, centeredSlides: false },
        700: { slidesPerView: 2.7, centeredSlides: false },
        950: { slidesPerView: 3.6, centeredSlides: false },
        1200: { slidesPerView: 4, centeredSlides: false },
      },
    };
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin"]),
  },
  methods: {
    publishedDate(module) {
      const date = new Date(module.published_at);
      return date.toLocaleDateString();
    },
    activate(module) {
      if (!this.isAdmin && module.is_blocked) {
        this.$message.error(
          `Este conteúdo estará disponível a partir de ${this.publishedDate(
            module
          )}`
        );
      } else {
        this.active = module;
        setTimeout(() => {
          document.getElementById("index-lessons-list").scrollIntoView();
        }, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index-lesson-modules {
  button {
    background-color: black;
    height: 0;
    padding-bottom: 140%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    border: none;
    position: relative;
    text-align: left;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(1.5);
    }

    .active-border {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 4px solid #deb34d;
      z-index: 1;
    }

    .block {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      color: white;
      background-color: rgba(0, 0, 0, 0.79);
      padding: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;

      i {
        font-size: 24px;
        margin-right: 16px;
      }
    }
  }
}
</style>
