<template>
  <div :class="`swiper-${_uid}`">
    <div class="swiper-wrapper">
      <slot />
    </div>

    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

export default {
  props: {
    initialSlide: {
      type: Number,
      default: 0,
    },
    slidesPerView: {
      type: Number,
      default: 1,
    },
    spaceBetween: {
      type: Number,
      default: 16,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    centeredSlides: {
      type: Boolean,
      default: false,
    },
    breakpoints: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    new Swiper(`.swiper-${this._uid}`, {
      modules: [Navigation],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: this.slidesPerView,
      spaceBetween: this.spaceBetween,
      loop: this.loop,
      centeredSlides: this.centeredSlides,
      breakpoints: this.breakpoints,
      initialSlide: this.initialSlide,
    });
  },
};
</script>

<style lang="scss">
.swiper-button-prev,
.swiper-button-next {
  color: white;
  height: 100%;
  top: 0;
  margin-top: 0;
  padding: 24px;

  &.swiper-button-disabled {
    display: none;
  }

  @media screen and (max-width: 767px) {
    padding: 16px;

    &::after {
      font-size: 32px;
    }
  }
}

.swiper-button-prev {
  left: 0;
  background-image: linear-gradient(to right, #0b0a0a, transparent);
}

.swiper-button-next {
  right: 0;
  background-image: linear-gradient(to right, transparent, #0b0a0a);
}
</style>
