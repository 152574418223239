<template>
  <action-dispatcher
    id="index-lessons-list"
    class="card"
    action="courses/getLessons"
    :parameters="module.id"
  >
    <div class="card-body">
      <h4 class="mb-0">{{ module.name }}</h4>
      <div class="text-secondary mb-3">Módulo {{ module.sort }}</div>

      <div class="row g-4">
        <div
          class="col-xl-3 col-lg-4 col-md-6"
          v-for="(lesson, index) in lessons"
          :key="lesson.id"
        >
          <component
            :is="isBlocked(lesson) ? 'div' : 'router-link'"
            class="text-light text-decoration-none"
            :to="{
              name: 'lesson',
              params: { id: lesson.id, slug: lesson.slug },
            }"
          >
            <div class="position-relative">
              <div class="ratio ratio-16x9">
                <img
                  :src="
                    lesson.cover ? lesson.assets.small : module.assets.small
                  "
                  class="img-fluid"
                />
              </div>
              <div v-if="isBlocked(lesson)" class="icon-wrapper">
                <i class="fa-regular fa-lock"></i>
              </div>
            </div>
            <div class="text-secondary text-uppercase mt-2 small">
              Aula {{ index + 1 }}
            </div>
            <h5 class="font-serif mt">{{ lesson.name }}</h5>
            <div
              v-html="striptags(lesson.description)"
              class="text-secondary lesson-description"
            ></div>
          </component>
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    module: Object,
  },
  computed: {
    ...mapState("courses", ["lessons"]),
    ...mapGetters("accounts", ["isAdmin"]),
  },
  methods: {
    isBlocked(lesson) {
      return !this.isAdmin && (this.module.is_blocked || lesson.is_blocked);
    },
    striptags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="scss" scoped>
#index-lessons-list {
  background: #131212;
  box-shadow: 4px 4px 10px rgba(39, 29, 102, 0.04);
  border-radius: 4px;
  margin-top: 24px;

  a {
    img {
      border-radius: 10px;
      transition: filter 0.2s;
    }

    &:hover img {
      filter: brightness(1.5);
    }
  }

  .icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    max-width: 64px;
    max-height: 64px;
    min-width: 64px;
    min-height: 64px;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 32px;
    }
  }

  .lesson-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
  }
}
</style>
